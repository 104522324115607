import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-form-textarea',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    ReactiveFormsModule
  ],
  templateUrl: './form-textarea.component.html',
  styleUrl: './form-textarea.component.scss'
})
export class FormTextareaComponent implements OnInit {

  @Input() placeholder: string = '';
  @Input() fCN: string = '';
  @Input() fG: FormGroup = new FormGroup({});
  @Input() errorText: string = '';
  @Input() style: 'inline'|'block'|'headless' = 'headless';
  @Input() disabled: boolean = false;
  @Input() maxChars: number = 0;
  @Output() blurred: EventEmitter<void> = new EventEmitter<void>();
  @Output() returnKeyPress: EventEmitter<void> = new EventEmitter<void>();

  public constructor() {
  }

  public ngOnInit(): void {
    if (this.disabled) {
      this.fG.controls[this.fCN].disable();
    }

    if (this.maxChars) {
      this.fG.controls[this.fCN].valueChanges.subscribe((value: string) => {
        if (value.length > this.maxChars) {
          this.fG.controls[this.fCN].setValue(value.substring(0, this.maxChars));
        }
      });
    }
  }

  public onReturnKeyPress(): void {
    this.returnKeyPress.emit();
  }

}
