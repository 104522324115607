import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {CheckBoxModule} from '@syncfusion/ej2-angular-buttons'

@Component({
  selector: 'app-form-checkbox',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CheckBoxModule
  ],
  templateUrl: './form-checkbox.component.html',
  styleUrl: './form-checkbox.component.scss'
})
export class FormCheckboxComponent implements OnInit {

  @Input() fCN: string = '';
  @Input() labelPosition: 'Before' | 'After' = 'After';
  @Input() value: string = '';
  @Input() label: string = '';
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false; // Optional, for disabled state
  @Input() cssClass: string = '';
  @Input() fG: FormGroup = new FormGroup({});

  public ngOnInit(): void {
    if (this.disabled) {
      this.fG.controls[this.fCN].disable();
    }
  }

}
